import React, { useContext } from "react"
import { Link } from "gatsby"
import PageLayout, { PageContext, PageData } from "../PageLayout"
import BlockContent from "@sanity/block-content-to-react"
import { PageMargin, PageTitle, Button } from "../GlobalStyles"
import styled from "styled-components/macro"

const ContactButton = styled(Button)`
  display: inline-flex;
`

const Content = () => {
  const pageData: PageData = useContext(PageContext)
  const { _rawDescription, _rawAdmissionsText, contactRep } = pageData

  return (
    <>
      <BlockContent blocks={_rawDescription} />
      <h2>Admissions</h2>
      <BlockContent blocks={_rawAdmissionsText} />
      <p>
        <strong>{`To learn more about the admission process or to make a referral, contact ${contactRep.contactRepImageName} in Admissions`}</strong>
      </p>
      <ContactButton as={Link} to="/contact">
        Contact
      </ContactButton>
    </>
  )
}

const About = () => {
  return (
    <PageLayout>
      <PageMargin>
        <PageTitle>About Us</PageTitle>
        <Content />
      </PageMargin>
    </PageLayout>
  )
}
export default About
